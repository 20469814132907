import React from 'react';

import Image from '../../image';
import Button from '../../Button';

const ImageText = ({
  Filename,
  storybookFilename,
  Position,
  title,
  text,
  alt,
  imgSize,
  download,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  borderShow,
}) => (
  <section data-theme="white">
    <div className="container container--lg margin-top--lg margin-bottom--lg margin---none">
      <div
        className={`grid grid--gap-lg flex--center-x flex--center-y ${Position}`}
      >
        <div className={`col col--8 col--sm-6 ${imgSize}`}>
          {Filename && (
          <Image
            filename={Filename}
            alt={alt}
            classname={`${borderShow}`}
          />
          )}
          {storybookFilename && (
          <img src="https://via.placeholder.com/300x150" alt="sb pic" />
          )}
        </div>
        <div className="col col--12 col--sm-6">
          <div className="text--component text--left list--checklist">
            <h2>{title}</h2>
            <p>{text}</p>

            {download && (
              <div className="margin-top--md">
                <Button
                  dataGoal={downloaddataGoal}
                  href={downloadhref}
                  classname={downloadclassname}
                  text={downloadtext}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default ImageText;
